export default {
  name: "OrderInProcessUpdateStatusLayout",
  data() {
    return {
      texts: "",
      statusSelected: "",
      statusDescription: "",
      aStatus: [],
      screenWidth: 0,
      bLoading: false,
      dataImgs: [],
    };
  },
  beforeMount() {
    this.texts = FILE.ordersTexts[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    this.closeButton();
  },
  methods: {
    sendUpdateStatus1: function() {
      let form = new FormData();
      let data = {};
      let arr = [];

      this.dataImgs.forEach((element) => {
        form.append("file", element);
      });
      // data = { file: arr };
    },
    sendUpdateStatus: function() {
      this.bLoading = true;
      this.$emit("blockBtn", true);

      const payload = {
        sDescription: this.statusDescription,
        sPurchaseOrderStatusId: this.statusSelected,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/status/purchase-orders/${this.dialogUpdateStatusArr.oPurchaseOrder.sPurchaseOrderId}`,
        payload,
        config
      )
        .then((response) => {
          if (this.dataImgs.length > 0) {
            this.uploadImgs(
              response.data.message,
              response.data.results.sPurchaseOrderId,
              response.data.results.sPurchaseOrderStatusRecordId
            );
          } else {
            this.bLoading = false;

            this.closeButton();
            this.mixSuccess(response.data.message);
            // this.$router.push("/admin/orders");
            this.$store.commit("refresher", true);
          }
        })
        .catch((error) => {
          this.bLoading = false;
          this.$emit("blockBtn", false);

          this.mixError(error.response.data.message, error.response.status);
        });
    },
    uploadImgs: function(message, orderId, recordId) {
      let form = new FormData();

      this.dataImgs.forEach((element) => {
        form.append("image", element);
      });

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.patch(
        `${URI}/api/v1/${this.selectLanguage}/purchase-orders/${orderId}/records/${recordId}/images`,
        form,
        config
      )
        .then((response) => {
          this.bLoading = false;

          this.closeButton();
          this.mixSuccess(message);
          // this.$router.push("/admin/orders");
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.bLoading = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
    removeItem: function(item, index) {
      this.dataImgs.splice(index, 1);
    },
    maskedFile: function(file) {
      return URL.createObjectURL(file);
    },
    uploadFilesWarehouse: function(e) {
      let totalImg = 0;
      const files = e.target.files;
      totalImg = this.dataImgs.length + files.length;
      if (totalImg < 11) {
        for (let i = 0; i < files.length; i++) {
          const element = files[i];
          this.dataImgs.push(element);
        }
        e.target.value = "";
      } else {
        this.mixError(this.texts.dialogUpdateStatus.textResponseLimitImages);
      }
    },
    getStatus: function() {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/status/purchase-orders/${this.dialogUpdateStatusArr.oPurchaseOrder.oStatusRecord.sOrderStatusId}/adjacency`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        }
      )
        .then((response) => {
          this.aStatus = response.data.results.aAdjacency;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    handleResize: function() {
      if (window.innerWidth >= 960) {
        this.screenWidth = 40;
      } else {
        this.screenWidth = 100;
      }
    },
    closeButton: function() {
      this.$store.commit("setDialogUpdateStatus", {
        active: false,
        arr: [],
      });
      this.dataImgs = [];
      this.statusSelected = "";
      this.statusDescription = "";
    },
    emitClose: function() {
      this.$store.commit("setDialogUpdateStatus", {
        active: false,
        arr: [],
      });
      this.dataImgs = [];
      this.statusSelected = "";
      this.statusDescription = "";
    },
  },
  computed: {
    dialogUpdateStatusActive: function() {
      return this.$store.state.dialogUpdateStatusActive;
    },
    dialogUpdateStatusArr: function() {
      return this.$store.state.dialogUpdateStatusArr;
    },
    selectLanguage: function() {
      return this.$store.state.language;
    },
    validateForm: function() {
      return (
        this.statusSelected !== "" &&
        this.statusSelected !== null &&
        this.statusDescription !== ""
      );
    },
  },
  watch: {
    dialogUpdateStatusActive: function() {
      if (this.dialogUpdateStatusActive) {
        this.texts = FILE.ordersTexts[this.selectLanguage];
        this.getStatus();
      }
    },
  },
};
